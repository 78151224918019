export const customerSettings = {
COMPANY_CODE: "delorge",
MODE: "production",
ENV: "production",
VERSION: 1.1,
DOMAIN: 'delorgecars.be',
SCRIPTS: [],
FONTS: [],
SENTRY_DSN: "https://356d953cae7ac936b8693143d56d2cbd@o1290476.ingest.us.sentry.io/4507509201764352",
LANGUAGES: ['nl', 'fr'],
FORCE_SINGLE_LANGUAGE_IN_URL: false,
WEBSITE_NAME: "A&M group",
IMAGE_PROMO_POSITION: "",
PATH_PREFIX: "",

GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_TAG_MANAGER_ID: ['GTM-PRWVM6F', 'GTM-5NWBJBS', 'GTM-K9K3SQ2P'],
HOTJAR_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
INTERCOM_ID: '',
FINANCING: true,
FINANCING_SETTINGA: 'Example',
FINANCING_TEXT: true,
RESERVATION: false,
FAVORITES: true,
VEHICLE_COMPARE: true,
SEO: true,
ACCOUNT: false
};